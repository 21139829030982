<template>
  <v-dialog
    v-model="alert.sync"
    container="sm"
    scrollable
  >
    <v-card>
      <div class="box" tpd="xl" halign="between">
        <div>
          {{ alert.title }}
        </div>
        <button @click="toggleAlert">
          <v-icon icon="mdi-window-close" size="x-small"></v-icon>
        </button>
      </div>
      <hr class="hr" bg="5xs">
      <div tpd="xl">
        {{ alert.contents }}
      </div>
      <hr class="hr" bg="5xs">
      <div class="box" tpd="xl" halign="end">
        <v-btn
          :title="alert.button"
          prepend-icon="mdi-check"
          variant="tonal"
          @click="toggleAlert"
        >
          {{ alert.button }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  setup() {
    
    // 글로벌 스토어 호출
    const { alert } = storeToRefs(useGlobalStore())
    const globalStore = useGlobalStore()

    return {
      alert,
      globalStore
    }
  },
  methods: {
    toggleAlert () {
      this.globalStore.toggleAlert()
    }
  }
}
</script>